import React from 'react'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'

import { landing_data_tours } from './data'

type CardProps = {
  img: string
  name: string
  description: string
  id: string
  categoryId: number
  link: string
  lat?: number
  lon?: number
  isLeft: boolean
}

export const ListedItem: React.FC<CardProps> = ({
  img,
  name,
  description,
  id,
  categoryId,
  link,
  lat,
  lon,
  isLeft,
}) => {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0)
    ReactGA.event({
      category: 'click',
      action: 'ClickedCardTour',
    })
  }

  return (
    <div className={'landing__item_tour'}>
      <Link
        hrefLang="en"
        onClick={handleClick}
        className={!isLeft ? 'landing__item_tour-right' : 'landing__item_tour-left'}
        to={link}
        state={{
          excursionId: id,
          categoryId: categoryId,
          lat: lat,
          lon: lon,
        }}
      >
        <h3>{name}</h3>
        <div className="landing__item_tour-img">
          <img src={img} alt={name} />
        </div>
        <div className="landing__item_tour-text">
          <p>{description}</p>
        </div>
      </Link>
    </div>
  )
}

interface CardInterface {
  selectedCategory: any
}

export const CardTour: React.FC<CardInterface> = ({ selectedCategory }) => {
  const filteredData = landing_data_tours.filter(
    (obj) =>
      selectedCategory === null ||
      (Array.isArray(selectedCategory) && selectedCategory.includes(obj.categoryId)),
  )
  const sortedData = [...filteredData]

  return (
    <div className="landing__card">
      <div className="container">
        <div className="card_wrapper">
          {sortedData.map((obj, i) => (
            <ListedItem key={i} {...obj} isLeft={i % 2 === 0} />
          ))}
        </div>
      </div>
    </div>
  )
}
