import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IGetPlaces } from '@/api/catalog.api'
import { IMapObject } from '@/core/interface'

import { Coordinates, CoordinatesAndHeading, Language, PositionUpdate } from '../interface/Default'

type AudioPlayingObject = {
  data: IMapObject
  minimised: boolean
}
interface iUiStore {
  cookiesAccepted: boolean
  locale: Language
  modal: string | null
  modalParams: { [key: string]: string }
  userRealPosition: Coordinates | null
  userLocation: Coordinates | null
  userPosition: CoordinatesAndHeading
  lastFetchedLocation: IGetPlaces | null
  locationManual: boolean
  mapLoaded: boolean
  markerOpenId: string
  audioPlaying?: AudioPlayingObject | null
  centeringAllow: boolean
}

const initialState: iUiStore = {
  cookiesAccepted: false,
  locale: 'en',
  modal: null,
  modalParams: {},
  userRealPosition: null,
  userLocation: null,
  userPosition: { latitude: 41.89021, longitude: 12.4923, heading: null }, //TODO: hack again!!!
  lastFetchedLocation: null,
  locationManual: false,
  mapLoaded: false,
  markerOpenId: '',
  audioPlaying: null,
  centeringAllow: true,
}

//TODO: for map flicker debug only
// eslint-disable-next-line no-var
var allowedPosUpdates = true

export const uiState = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCookiesAccepted(state, action: PayloadAction<boolean>) {
      state.cookiesAccepted = action.payload
    },
    setLocale(state, action: PayloadAction<Language>) {
      state.locale = action.payload
    },
    setModal(state, action: PayloadAction<{ name: string; params?: any }>) {
      if (action.payload.name) {
        state.modal = action.payload.name
      }
    },
    closeModal(state) {
      state.modal = null
    },
    changeCentering(state, action: PayloadAction<boolean>) {
      state.centeringAllow = action.payload
    },
    setUserLocation(state, action: PayloadAction<Coordinates | null>) {
      //Location drives all the app logic (closest objects, etc.) - changes not very frequently
      //lots of triggering happens when location changes!!!
      if (action.payload) {
        let { latitude, longitude } = action.payload
        //TODO: Make it a nicer solution
        if ((latitude > 42.05 || latitude < 41.72) && (longitude < 12.146 || longitude > 12.7777)) {
          latitude = 41.89021
          longitude = 12.4923
        }
        state.userLocation = { latitude, longitude }
      }
    },
    setUserRealPosition(state, action: PayloadAction<PositionUpdate>) {
      const { latitude, longitude } = action.payload
      if (latitude !== null && longitude !== null) state.userRealPosition = { latitude, longitude }
    },
    setUserPosition(state, action: PayloadAction<PositionUpdate>) {
      let { latitude, longitude } = action.payload
      const { heading } = action.payload

      if (allowedPosUpdates) {
        //console.log('Position updated')

        //Position comes from geopositioning - sometimes frequently
        if (latitude && longitude) {
          // eslint-disable-next-line prefer-const
          //TODO: Make it a nicer solution
          if (
            (latitude > 42.05 || latitude < 41.72) &&
            (longitude < 12.146 || longitude > 12.7777)
          ) {
            latitude = 41.89021
            longitude = 12.4923
          }
          const updatedHeading = heading ? heading : state.userPosition.heading
          state.userPosition = { latitude, longitude, heading: updatedHeading }
        } else if (heading) {
          state.userPosition.heading = heading
        }
      }
    },
    setLastFetchedLocation(state, action: PayloadAction<IGetPlaces | null>) {
      if (action.payload) {
        state.lastFetchedLocation = action.payload
      }
    },
    setLocationManual(state, action: PayloadAction<boolean>) {
      state.locationManual = action.payload
    },
    setMapLoaded(state, action: PayloadAction<boolean>) {
      state.mapLoaded = action.payload
    },
    setmarkerOpenId(state, action: PayloadAction<string>) {
      console.log('ui.state', setmarkerOpenId)
      state.markerOpenId = action.payload
    },
    setAudioPlay(state, action: PayloadAction<IMapObject>) {
      state.audioPlaying = {
        data: action.payload,
        minimised: true,
      }
      // state.audioPlaying.data
      //state.audioPlaying.data = action.payload
      //state.audioPlaying.minimised = true

      console.log('--after', state)
    },
    toggleAudioState(state) {
      if (state.audioPlaying != null) {
        state.audioPlaying.minimised = !state.audioPlaying.minimised
      }
    },
    closeAudioPlay(state) {
      state.audioPlaying = null
    },
  },
})

export const {
  setCookiesAccepted,
  setUserRealPosition,
  setUserLocation,
  setUserPosition,
  setLastFetchedLocation,
  setLocationManual,
  setLocale,
  setModal,
  closeModal,
  setMapLoaded,
  setmarkerOpenId,
  setAudioPlay,
  toggleAudioState,
  closeAudioPlay,
  changeCentering,
} = uiState.actions

export default uiState.reducer
