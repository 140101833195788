import { MainMap } from '@c/Catalog'
import cns from 'classnames'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { CategoriesForMap } from '@/components/Catalog/Map/CategoriesForMap'
import SentryErrorBoundary from '@/components/SentryErrorBoundary'
import { useAppSelector } from '@/core/store'

const Home = () => {
  const { interestPoints, selectedCategories } = useAppSelector((store) => store.catalogStore)
  const location = useLocation()

  // const filteredPoints = useMemo(() => {
  //   const res = interestPoints.filter((point) =>
  //     point.category.some((x) => selectedCategories.includes(x)),
  //   )
  //   return res
  // }, [selectedCategories, interestPoints])

  const filteredPoints = useMemo(() => {
    const res = interestPoints.filter((point) => {
      // Check if the point matches the category criteria
      const matchesCategory =
        selectedCategories.category === 0 || // Accept any importance
        (selectedCategories.category === 1 &&
          point.category.some((cat) => ['10', '7'].includes(cat))) || // '10' or '7'
        (selectedCategories.category === 2 && point.category.includes('!')) // '!'

      // Helper function to check if a point category matches an included category
      const categoryMatches = (pointCat: string, includedCat: string) => {
        if (includedCat === 'B' && (pointCat === 'B' || pointCat === 'B-M' || pointCat === 'B-P'))
          return true
        if (includedCat === 'M' && (pointCat === 'M' || pointCat === 'B-M')) return true

        //Option 'Other' enables anything that starts with a letter (i.e. not !,10,7,5,3 and not in the ist above)
        if (
          includedCat === 'Other' &&
          !['B', 'M', 'B-M', 'B-P', 'C', 'S', 'P', 'S-P', 'Ponte'].includes(pointCat) &&
          /^[A-Z]/.test(pointCat)
        )
          return true
        return pointCat === includedCat
      }

      // Check if the point has at least one category that matches the included list
      const matchesIncluded = point.category.some((pointCat) =>
        selectedCategories.included.some((includedCat) => categoryMatches(pointCat, includedCat)),
      )

      // Return true if the point matches both criteria
      return matchesCategory && matchesIncluded
    })

    // console.log(
    //   'MainMap Filtered:',
    //   selectedCategories.category,
    //   selectedCategories.included,
    //   res.length,
    //   'points',
    // )

    return res
  }, [selectedCategories, interestPoints])

  const [isExcursionBeingSelected, setExcursionBeingSelected] = useState(() => {
    return !!location.state?.excursionId || !!location.state?.categoryId
  })

  return (
    <div className={cns('home')}>
      <SentryErrorBoundary boundaryId="homepage">
        <MainMap
          markers={filteredPoints}
          isExcursionBeingSelected={isExcursionBeingSelected}
          setExcursionBeingSelected={setExcursionBeingSelected}
          initialExcursionId={location.state?.excursionId}
          initialCategoryId={location.state?.categoryId}
        />
        <CategoriesForMap
          isExcursionBeingSelected={isExcursionBeingSelected}
          setExcursionBeingSelected={setExcursionBeingSelected}
        />
      </SentryErrorBoundary>
    </div>
  )
}

export default Home
