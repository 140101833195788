import { Copyright } from '@c/Content/Copyright'
import { FeedbackModal } from '@c/Feedback'
import { Header, LayoutCookie } from '@c/Layout'
import { PaymentModal } from '@c/Profile'
import { Loader } from '@c/Ui'
import { useJsApiLoader } from '@react-google-maps/api'
import Cookies from 'js-cookie'
import { nanoid } from 'nanoid'
import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { useGeolocation } from '@/core/hooks/useGeolocation'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { setModal } from '@/core/store/ui.store'
import HomePage from '@/pages/Home.page'
import WelcomePage from '@/pages/Welcome.page'

import { Assistent } from './components'

const App: React.FC = () => {
  //remove the loading message
  useEffect(() => {
    // Remove the initial loader
    const loader = document.getElementById('initial-loader')
    if (loader) {
      loader.style.display = 'none'
    }
  }, [])

  const { modal } = useAppSelector((store) => store.uiState)
  const { interestPoints, playedAudioIds, isPayedClient } = useAppSelector(
    (store) => store.catalogStore,
  )

  const dispatch = useAppDispatch()
  const { initGeo } = useGeolocation()
  const { t, i18n } = useTranslation('root')
  const location = useLocation()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY as string,
  })

  const isLanding = location.pathname.includes('begin') || location.pathname.includes('welcome')
  const isRealBrowser = !('ontouchstart' in window || navigator.maxTouchPoints)
  const [initialPageViewSent, setInitialPageViewSent] = useState(false)

  useEffect(() => {
    {
      const searchParams = new URLSearchParams(location.search)
      const campaign = searchParams.get('c')

      const id = Cookies.get('userID') || nanoid()

      Cookies.set('userID', id)

      const tagVals = {
        user_id: id,
        locale: i18n.language,
        userLanguage: navigator.language,
        userAgent: navigator.userAgent,
        screen: `${window.innerWidth}x${window.innerHeight}`,
        ...(campaign && { campaign: campaign }),
      }
      ReactGA.gtag('set', tagVals)

      ReactGA.initialize('G-QWRFFFZ2YM')

      timer.current = setInterval(() => {
        ReactGA.event({
          category: 'session',
          action: 'SessionRunning',
        })
      }, 5 * 60 * 1000)

      return () => {
        clearInterval(timer.current as NodeJS.Timeout)
      }
    }
  }, [])

  useEffect(() => {
    const pageLoadDelay = 6000 // 6 seconds (adjust as needed)

    if (isRealBrowser) {
      if (!initialPageViewSent) {
        setInitialPageViewSent(true)
      } else {
        const timer = setTimeout(() => {
          ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
          })
        }, pageLoadDelay)

        return () => {
          clearTimeout(timer) // Clear the timer if the component unmounts before the delay
        }
      }
    }
  }, [location])

  //Monitor played audios and issue payment requests
  useEffect(() => {
    if (playedAudioIds.length > 3 && !isPayedClient) {
      if (modal !== 'payment') {
        //Stop all audios
        Array.from(document.querySelectorAll('audio')).forEach((audio) => {
          if (!audio.paused) audio.pause()
        })

        dispatch(setModal({ name: 'payment' }))
        {
          ReactGA.event({
            category: 'payment',
            action: 'PaymentRequested',
          })
        }
      }
    }
  }, [playedAudioIds, isPayedClient])

  const timer: { current: NodeJS.Timeout | null } = useRef(null)

  {
    if (!isLanding) {
      //TODO: for some reason this is called multiple times, need to fix it
      //Retrieve geolocation - only if we are not on landing page
      initGeo()
    }
  }

  if (!isLanding && (!isLoaded || interestPoints.length === 0)) {
    return (
      <HelmetProvider>
        {/* {!isLanding && <Header />} */}
        <Header />

        {<Loader theme="page" active={true} />}

        <PaymentModal />
        <FeedbackModal />
        <ToastContainer />
        <LayoutCookie />
      </HelmetProvider>
    )
  }

  return (
    <HelmetProvider>
      {!isLanding && (
        <Helmet>
          <title>WorldMuseum: Audio Map of Rome</title>
        </Helmet>
      )}

      {!isLanding && <Header />}

      {
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/begin" element={<Navigate to="/" replace />} />
          <Route path="/map" element={<HomePage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      }

      <PaymentModal />
      <FeedbackModal />
      <ToastContainer />
      <LayoutCookie />
      {/* <Assistent /> */}
    </HelmetProvider>
  )
}

export default App
