import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from '@/core/store'

const FirstTimePopup: React.FC = () => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation('firstTimePopup')
  const location = useLocation()
  const cookiesAccepted = useAppSelector((state) => state.uiState.cookiesAccepted)

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenFirstTimePopup')
    const isExcursionStart = location.search.startsWith('?startExcursion')

    if (!hasSeenPopup && cookiesAccepted && !isExcursionStart) {
      const timer = setTimeout(() => setShow(true), 1000) // Delay popup appearance
      return () => clearTimeout(timer)
    }
  }, [location.search, cookiesAccepted])

  useEffect(() => {
    if (show) {
      const hidePopup = () => {
        setShow(false)
        localStorage.setItem('hasSeenFirstTimePopup', 'true')
      }

      const timer = setTimeout(() => {
        document.addEventListener('click', hidePopup)
      }, 2000) // Delay to prevent immediate closing

      return () => {
        clearTimeout(timer)
        document.removeEventListener('click', hidePopup)
      }
    }
  }, [show])

  if (!show) return null

  const message = t(
    'message',
    'Tap any object to get an audio guide - or choose one of the great routes below',
  )

  return (
    <div className="first-time-popup">
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

export default FirstTimePopup
