import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Card } from '../components/Landing/Card'
import { CardTour } from '../components/Landing/CardTour'
import { Hero } from '../components/Landing/Hero'

const Welcome = () => {
  // const [selectedCategory, setSelectedCategory]: [
  //   string[] | null,
  //   React.Dispatch<React.SetStateAction<string[] | null>>,
  // ] = useState(null)

  const [selectedCategory, setSelectedCategory] = useState<any>(null)

  //const [selectedCategory, setSelectedCategory] = useState<string[] | null>(null)

  const navigate = useNavigate()
  const { t } = useTranslation('landing')

  return (
    <div className="landing">
      <Hero />
      <div className="landing__wrapper">
        <CardTour selectedCategory={selectedCategory} />
        {/* <Card selectedCategory={selectedCategory} /> */}
      </div>
    </div>
  )
}

export default Welcome
